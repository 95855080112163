.color-white {
    color: var(--text-primary);
}

.success {
    color: var(--success);
}

.error {
    color: var(--error);
}

.primary {
    color: var(--primary);
}

.secondary {
    color: var(--text-secondary);
}

.warning {
    color: var(--yellow);
}

.inherit {
    color: inherit;
}

.color-accent1 {
    color: var(--accent1);
}

.color-accent2 {
    color: var(--purple);
}

.color-accent3 {
    color: var(--accent3);
}

.color-accent4 {
    color: var(--accent4);
}

.color-accent5 {
    color: var(--accent5);
}
