.formWrap {
    position: relative;
    border: 1px solid transparent;
    height: 100%;
    min-height: 570px;
    min-width: 250px;
    overflow: hidden auto;
}

.screenWrap {
    position: absolute;
    top: 0;
    width: 100%;
}
