.flex {
    display: flex;
}

.inline-flex {
    display: inline-flex;
}

.flex-direction-column {
    display: flex;
    flex-direction: column;
}

.flex-wrap {
    display: flex;
    flex-wrap: wrap;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.flex-push-left {
    margin-right: auto;
}

.flex-push-right {
    margin-left: auto;
}

.flex-justify-end {
    justify-content: flex-end;
}

.flex-align-start {
    display: flex;
    align-items: flex-start;
}

.flex-align-center {
    align-items: center;
}

.flex-justify-space-between {
    justify-content: space-between;
}
