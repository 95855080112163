h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 8px;
}

h1,
.MuiTypography-h1 {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0;
}

h2,
.MuiTypography-h2 {
    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0;
}

h3,
.MuiTypography-h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0;
}

h4,
.MuiTypography-h4 {
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.2px;
}

h5,
.MuiTypography-h5 {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0;
}

h6,
.MuiTypography-h6 {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0;
}

.subtitle1,
.MuiTypography-subtitle1 {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0;
}

.subtitle2,
.MuiTypography-subtitle2 {
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.2px;
    text-align: left;
}

.body1,
.MuiTypography-body1 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
}

.body2,
.MuiTypography-body2 {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.2px;
}

.input-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}

.helper-text {
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.2px;
}

.MuiTypography-overline {
    /* TODO not set */
}

.MuiTypography-button {
    /* TODO not set */
}

.MuiTypography-caption {
    /* TODO not set */
}

.font-weight-500 {
    font-weight: 500;
}

.font-size-inherit.font-size-inherit {
    font-size: inherit;
}

.text-align-center {
    text-align: center;
}

.text-align-right {
    text-align: right;
}

.text-align-justify {
    text-align: justify;
}

.white-space-nowrap {
    white-space: nowrap;
}

.white-space-break-spaces {
    white-space: break-spaces;
}

.word-break-all {
    word-break: break-all;
}

.text-decoration-none {
    text-decoration: none;
}

.text-transform-uppercase {
    text-transform: uppercase;
}

.text-transform-initial {
    text-transform: initial;
}

.text-transform-uppercase {
    text-transform: uppercase;
}

.vertical-align-middle.vertical-align-middle {
    vertical-align: middle;
}
