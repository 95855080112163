.heading {
    position: relative;
    height: 104px;
    margin-bottom: 64px;
}

.heading.success {
    color: var(--success);
}

.heading.error {
    color: var(--error);
}

.iconBox {
    width: 64px;
    height: 64px;
    border-radius: var(--border-radius-xl);
    background: currentColor;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
}

.iconBox svg {
    color: var(--bg-paper);
}

.title {
    font-size: 32px;
    text-align: center;
    margin-bottom: 24px;
}

.body {
    font-size: 16px;
    text-align: center;
    margin: 0 auto 64px;
    max-width: 420px;
}
