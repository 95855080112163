.bgDark {
    background: var(--bg-black);
}

.bgLight {
    background: var(--bg-default);
}

.fixed {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
}

.container {
    display: flex;
    line-height: 1;
    padding: 24px 0;
    justify-content: space-between;
}

.actionButton {
    min-width: 124px;
}

.progress,
.progress :global(.MuiLinearProgress-bar) {
    border-radius: 0;
}
