.group {
    width: 100%;
}

.primaryAction.primaryAction {
    width: 100%;
    min-width: 64px;
}

.moreActionsButton.moreActionsButton {
    min-width: 32px;
    padding: 0;
}
