.treeItem {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 16px 8px 0;
    transition: 0.2s ease background-color;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.2px;
    cursor: pointer;
    color: currentColor;
}

.treeItem.interactive:hover,
.treeItem.interactive.active {
    background-color: var(--primary-a10);
}

.offset0 {
    padding-left: var(--base-level-offset);
}

.offset1 {
    padding-left: calc(var(--base-level-offset) + var(--level-offset));
}

.offset2 {
    padding-left: calc(var(--base-level-offset) + var(--level-offset) * 2);
}
