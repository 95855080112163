:global(.rowWithFloatingPanelCell):hover .panelWrap {
    opacity: 1;
    pointer-events: all;
}

.cell {
    position: relative;
    z-index: 0;
}

.panelWrap {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    background: var(--bg-paper-light);
    opacity: 0;
    transition: 0.2s ease opacity;
    pointer-events: none;
    padding: 0 0 0 12px;
}
