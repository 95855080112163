.container {
    position: relative;
}

.spinner-wrapper {
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
}

div.spinner {
    font-size: 14px;
    height: 1.25rem;
    width: 1.25rem;
}

.disabledDateTooltip > button {
    color: var(--text-secondary-dark);
    cursor: not-allowed;
}

.holidayPointContainer {
    position: relative;
}

.holidayPoint {
    position: absolute;
    top: 28px;
    left: 18px;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background-color: var(--secondary);
    z-index: 1500;
}
