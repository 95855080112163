.wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
}

.icon {
    font-size: 40px;
    margin-bottom: 24px;
}

.textWrap {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.text {
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
    margin-bottom: 4px;
}

.descriptionText {
    font-size: 14px;
    font-weight: 500;
    color: var(--secondary);
    text-transform: none;
}

.addButton {
    min-width: 100px;
}
