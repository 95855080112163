.ototContainer {
    padding: 12px;
    margin-bottom: 20px;
}

.ototContainer > div {
    background-color: var(--bg-paper-dark);
    padding: 12px;
}

.multiDisplayPre {
    color: var(--text-primary);
    font-size: 12px;
    font-family: inherit;
    margin-bottom: 0;
    margin-top: 0;
    text-overflow: ellipsis;
    white-space: pre;
}
