.wrap {
    border-radius: var(--border-radius-md);
    background: #fff;
    width: 100%;
    height: 100%;
    max-width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.codeImage {
    width: 100%;
}
