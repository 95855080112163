.connectorWrap {
    padding-top: 2px;
    padding-bottom: 2px;
    pointer-events: none;
}

.connector {
    display: flex;
}

.connector:before {
    content: '';
    display: flex;
    background-color: var(--secondary);
    width: 2px;
    height: 28px;
    margin-left: 37px;
}
