.badgeIcon > span {
    font-weight: 600;
    color: #ffffff;
}

.badgeIcon.colored > span {
    background-color: var(--accent6);
}

.badgeIcon.transparent > span {
    background: transparent;
}

.notificationsList {
    border-radius: var(--border-radius-xs);
    width: 450px;
}

.notificationsListHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    font-weight: 600;
}

.notificationsListFooter {
    font-weight: 600;
    transition: 0.2s ease background-color;
    cursor: pointer;
    padding: 16px;
    text-align: center;
    background-color: var(--bg-paper-elevation1);
    border-top: 2px solid var(--bg-paper-dark);
}

.notificationsListFooter:hover {
    background-color: var(--primary-a20);
}

.contentPadding {
    padding: 16px;
}

.notificationItem {
    margin-bottom: 0;
    padding: 16px 40px;
}
