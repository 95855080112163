.link {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    color: currentColor;
}

.linkIcon {
    margin-left: 2px;
}
