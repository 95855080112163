.title {
    font-size: 28px;
    font-weight: 600;
    margin: 0 0 20px 0;
    line-height: 1.25;
}

.description {
    font-size: 16px;
    line-height: 1.25;
    font-weight: 600;
}
