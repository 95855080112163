.icon {
    font-size: 64px;
    margin-bottom: 32px;
}

.text {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: var(--text-primary);
}
