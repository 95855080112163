.row {
    display: flex;
    align-items: center;
    padding: 14px 0;
    font-size: 14px;
}

.row:not(:last-child) {
    border-bottom: 1px solid var(--divider);
}

.nameCol {
    width: 300px;
    color: var(--text-secondary);
}

.valueCol {
    width: 100%;
    text-align: end;
    display: flex;
    justify-content: flex-end;
}
