.dropAreaContainer {
    position: sticky;
    top: -16px;
    z-index: 1;
    padding: 16px 0;
    background: var(--bg-paper);
}

.privateAccessLabel {
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin: 0;
}
