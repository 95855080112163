.divider.divider {
    margin-bottom: 0;
}

.subheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 8px;
    margin-bottom: 4px;
    margin-top: 8px;
}

.list {
    padding: 0 0 8px 0;
}

.scrollContainer {
    max-height: 250px;
    overflow: auto;
}

.listItem {
    padding: 0 4px;
}
