.wrap {
    color: var(--text-secondary);
    transition: 0.2s ease color;
}

.wrap:hover,
.wrap.active {
    color: var(--text-primary);
}

.circle {
    font-size: 16px;
    color: currentColor;
    margin-right: 12px;
    margin-left: 30px;
}
