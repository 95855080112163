.wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px;
}

.retryButton {
    padding-right: 16px;
}

.retryButtonIcon {
    margin: -8px 0;
}
