.header {
    z-index: var(--app-heading-portal-z-index);
}

.scrollableArea {
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
    overflow-y: auto;
    scrollbar-gutter: stable;
}

.pageHeadingContainer {
    position: sticky;
    top: 0;
    background-color: var(--bg-default);
    z-index: var(--app-heading-portal-z-index);
}

.pageHeading {
    display: flex;
    padding: 12px 0;
}

.pageHeading :global(.primaryAction) {
    min-width: 130px;
}

.pageHeading button {
    white-space: pre;
}

.pageHeading a:hover {
    text-decoration-line: none;
}

.pageHeading > :global(a),
.pageHeading > :global(.btn) {
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    height: 32px;
}
