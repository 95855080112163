.value {
    display: flex;
    align-items: center;
    border: 1px solid var(--text-secondary);
    border-radius: 6px;
    padding: 12px;
    line-height: 1;
    color: var(--secondary);
    min-height: 40px;
    position: relative;
    flex: 1;
    min-width: 200px;
}
