@font-face {
    font-family: 'Gilroy';
    src: url('gilroy-light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('gilroy-regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('gilroy-medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('gilroy-semibold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}
