.dialog {
    max-width: 800px;
    border-radius: var(--border-radius-lg);
}

.closeButton {
    position: fixed;
    top: 40px;
    right: 40px;
    margin: 0;
}

.acknowledgeText {
    font-size: 12px;
    text-align: justify;
}

.submitButton {
    margin-left: 64px;
    min-width: 120px;
}

.submitModeFrame {
    height: 80vh;
}
