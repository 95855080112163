.wrapMinHeight {
    min-height: 130px;
}

.wrap:global(.embedded) .noElementsMessage > * {
    background: transparent;
}

.wrap .noElementsMessage > div {
    background: var(--bg-paper);
}

.shrinkWidth {
    width: 1px;
}

.gqlListTable {
    min-height: 100px;
}
