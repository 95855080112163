.name {
    font-size: 14px;
    font-weight: 600;
    word-break: break-all;
}

.fileNameWrap {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.167;
    color: var(--secondary);
    white-space: nowrap;
    display: flex;
}

.fileNameText {
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}
