.moreOptionsButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--secondary);
    border-bottom: transparent solid 2px;
}

.active {
    color: var(--primary);
}
