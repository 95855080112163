.container {
    --left-padding: 40px;

    padding-left: var(--left-padding);
}

.titleWrap {
    display: flex;
    align-items: center;
    position: relative;
    flex: 1;
}

.title {
    font-size: 22px;
    letter-spacing: 0.2px;
    font-weight: 600;
    margin-right: auto;
}

.requiredAsterisk:after {
    content: '*';
    margin-left: 2px;
    color: var(--error);
    line-height: 0;
    user-select: none;
}

.completeIcon {
    position: absolute;
    left: calc(var(--left-padding) * -1);
    top: 50%;
    transform: translateY(-50%);
}

.description {
    width: 80%;
    margin-bottom: 16px;
    min-height: 16px;
}
