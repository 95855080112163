.searchIcon {
    font-size: 18px;
    color: var(--secondary);
}

.clearButton {
    font-size: 16px;
    padding: 6px;
}

.textField {
    /* to prevent outline be under next/prev component which is relatively positioned */
    z-index: 1;
}

.textField :global(.MuiInputBase-root) {
    padding-right: 8px;
}
