.button {
    padding-right: 40px;
    font-size: 16px;
    font-weight: 600;
    min-width: 230px;
    position: relative;
}

.arrow {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}
