.header {
    min-height: 64px;
}

.logo,
.logoSmall {
    height: 24px;
    margin-right: 24px;
    margin-top: -6px;
}

.logo {
    display: block;
}

.logoSmall {
    display: none;
}

@media (max-width: 980px) {
    .logo {
        display: none;
    }

    .logoSmall {
        display: block;
    }
}

.logoLink {
    display: flex;
    align-items: center;
}

.headerContainer {
    background-color: var(--control-bg);
}
