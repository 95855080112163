.managedChip {
    margin-left: 16px;
    font-size: 12px;
    border-radius: var(--border-radius-xs);
    background-color: var(--primary-a10);
    color: var(--primary);
    text-transform: uppercase;
}

.compact {
    height: 18px;
}

.capabilitiesContainer {
    padding: 4px 8px;
    background-color: var(--bg-default);
}

.capabilityLabel {
    font-size: 12px;
    line-height: 14px;
    display: block;
    margin: 16px;
}
