.folder {
    --level-offset: 16px;
    --base-level-offset: calc(var(--level-offset) / 2);

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.iconsWrap {
    display: flex;
    margin: -4px 0;
}

.folderIcon {
    font-size: 24px;
    margin: 0 10px 0 2px;
}

.folderExpandIcon {
    font-size: 24px;
    color: var(--text-primary);
    transition: 0.2s ease transform;
    transform: rotate(-90deg);
}

.folderExpandIcon.expanded {
    transform: rotate(0);
}

.contentWrap {
    width: 100%;
}
