/* Workaround for colspan when rendered using divs */
div.row {
    height: var(--row-min-height);
}

.row > div {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    height: var(--row-min-height);
    background: var(--bg-paper);
}

.childrenWrap {
    display: block;
}
