.wrap {
    white-space: break-spaces;
}

.countryNameText {
    line-height: 1.5;
    vertical-align: middle;
}

.countryNameText:not(:only-child) {
    margin-left: 4px;
}
