.button {
    color: var(--secondary);
    margin-right: -12px;
    border-radius: 6px;
    padding: 10px;
    transition: 0.2s ease color, 0.2s ease background-color;
    margin-left: auto;
}

.button:hover {
    color: var(--text-primary);
}

.button:focus {
    outline: none;
}

.button [class^='MuiSvgIcon-root'] {
    width: 20px;
    height: 20px;
}
