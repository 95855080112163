.wrap {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    min-height: 38px;
}

.title {
    font-size: 28px;
    font-weight: 600;
    line-height: 1.22;
    letter-spacing: 0;
    margin: 0;
}
