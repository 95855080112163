tr td.wrap {
    padding: 24px;
}

.wrap {
    background: var(--bg-paper);
}

.darkBg .wrap {
    background: var(--bg-paper-dark);
}

.collapse {
    display: none;
}

.expand {
    display: table-row;
}

.expand > td:first-child {
    padding-left: 14px;
    border-top-left-radius: 0;
}

.expand > td:last-child {
    padding-right: 14px;
    border-top-right-radius: 0;
}
