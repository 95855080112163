.fileIconCell.fileIconCell {
    padding-left: 11px;
    padding-right: 6px;
}

.fileIconWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
}

.fileIconWrap > svg {
    font-size: 20px;
}
