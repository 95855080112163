/**
    TODO get rid of rarely-used variables, Align colors with material color scheme
 */
:root {
    --primary: #4d7cfe;
    --primary-a10: #4d7cfe1a;
    --primary-a20: #4d7cfe33;
    --primary-dark: #2f5cd8;
    --secondary: #8590b3;
    --secondary-a50: #8590b380;

    --text-primary: #e8e8e8;
    --text-secondary: #8590b3;
    --text-secondary-dark: #8181a5;

    --success: #01eee0;
    --success-a20: #01eee033;
    --success-a30: #01eee04d;
    --success-dark: #23b899;

    --error: #ff5c5c;
    --error-dark: #c2454b;
    --error-a10: #ff5c5c1a;
    --error-a20: #ff5c5c33;
    --error-a30: #ff5c5c4a;

    --warning: #ed6c02;
    --warning-dark: #e65100;
    --warning-light: #ff9800;
    --warning-a20: #ed6c0233;

    --border-dark: #272e48;

    --border-radius-xs: 4px;
    --border-radius-sm: 6px;
    --border-radius-md: 8px;
    --border-radius-lg: 10px;
    --border-radius-xl: 20px;

    --bg-app: #1d2b3c;
    --bg-paper: #293049;
    --bg-paper-dark: #262d44;
    --bg-paper-light: #323c60;
    --bg-default: #21273d;

    /* Accents */
    --purple: #8978ff;
    --purple-a20: #8978ff33;
    --yellow: #e39826;
    --yellow-a20: #ffab2b33;

    --accent1: var(--success);
    --accent3: #d96bff;
    --accent4: #ffdaa0;
    --accent5: #0094ff;
    --accent6: #fe4d97;
    --accent7: #24345a;

    --gradient-success-end-color: #1a6b77;
    --gradient-success: linear-gradient(
        to right,
        #243249,
        #224058,
        #1e4e65,
        #195c6f,
        var(--gradient-success-end-color)
    );

    --font-main: 'Gilroy', Arial, sans-serif;
    --logo-small: url('img/ledgercomm-logo-20x20.png');
    --bg-pattern: url('../../public/img/bg-pattern.svg');

    /* shouldn't be over 1299, as MUI dialog container has the z-index od 1300 */
    --app-heading-portal-z-index: 1200;

    /* Rarely-used & odd variables. TODO get rid of */
    --bg-black: #141725;
    --bg-black-a80: #141725cc;
    --card-bg: #29354c;
    --bg-paper-elevation1: #323a58;
    --control-bg: #172331;
    --divider: #3e4356;
    --caption: #72778a;
    --caption-lighter: #989898;
    --table-bg: #223145;
    --table-row-inactive-color: var(--bg-app);
    --table-row-active-bg: #2c4460;
    --logo-color: #4dacff;
    --row-min-height: 42px;
}
