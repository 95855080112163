*,
::after,
::before {
    box-sizing: border-box;
}

body,
html {
    font-family: var(--font-main);
    font-size: 14px;
    color: #e8e8e8;
    background: var(--bg-default);
    height: 100%;
}

/* Override default autofilled input styles on chrome */
input:-webkit-autofill:-webkit-autofill,
input:-webkit-autofill:-webkit-autofill:hover,
input:-webkit-autofill:-webkit-autofill:focus,
input:-webkit-autofill:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--control-bg) inset;
    -webkit-text-fill-color: white;
    border-radius: inherit;
}

/* Remove spinner from inputs fields */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
/* Remove spinner from inputs fields */

a,
a:hover {
    color: var(--primary);
    transition: 0.2s ease color;
    text-decoration: none;
}

a:hover {
    color: var(--primary-dark);
}

::placeholder {
    color: var(--secondary);
}
