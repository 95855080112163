.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

.fullWidth {
    width: 100%;
}

.full-height {
    height: 100%;
}

.mt-4 {
    margin-top: 4px;
}

.ml-4 {
    margin-left: 4px;
}

.ml-8 {
    margin-left: 8px;
}

.mr-8 {
    margin-right: 8px;
}

.mr-4 {
    margin-right: 4px;
}

.mr-12 {
    margin-right: 12px;
}

.mr-16 {
    margin-right: 16px;
}

.ml-12 {
    margin-left: 12px;
}

.ml-16 {
    margin-left: 16px;
}

.mb-16 {
    margin-bottom: 16px;
}

.mb-24 {
    margin-bottom: 24px;
}

.mb-32 {
    margin-bottom: 32px;
}

.mb-4 {
    margin-bottom: 4px;
}

.mb-8 {
    margin-bottom: 8px;
}

.mb-0 {
    margin-bottom: 0;
}

.pr-0 {
    padding-right: 0;
}

.pr-8 {
    padding-right: 8px;
}

.pl-0 {
    padding-left: 0;
}

.pl-16 {
    padding-left: 16px;
}

.nomargin {
    margin: 0;
}

.nopadding {
    padding: 0;
}

.mt-16 {
    margin-top: 16px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-auto {
    margin-top: auto;
}

.mr-auto {
    margin-right: auto;
}

.ml-auto {
    margin-left: auto;
}

.display-none {
    display: none;
}
