.tag {
    font-size: 12px;
    line-height: 1;
    font-weight: 600;
    text-transform: uppercase;
    padding: 6px 8px;
    border-radius: var(--border-radius-sm);
}

.yellow {
    background: var(--yellow-a20);
    color: var(--yellow);
}

.purple {
    background: var(--purple-a20);
    color: var(--purple);
}
