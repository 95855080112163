/* Everything in this file is deprecated */
/* TODO: get rid of this file */

th,
td {
    font-size: 12px;
    letter-spacing: 0.2px;
}

.table-dark td,
.table-dark th,
.table-dark thead th,
.table th,
.table td {
    border-color: var(--bg-default);
    border-width: 4px;
}

#app {
    display: flex;
    flex-flow: column;
    height: 100%;
}

a {
    cursor: pointer;
}

/* Bootstrap is messing with material buttons */
button[class*='Mui']:focus {
    outline: none;
}

h4 {
    display: inline-block;
}

table:not([class^='MuiTable']) {
    background-color: unset !important;
}

table:not([class^='MuiTable']) tbody {
    background-color: var(--bg-paper);
}

table:not([class^='MuiTable']) th,
table:not([class^='MuiTable']) td {
    text-transform: uppercase;
    font-weight: 400;
    color: var(--text-secondary);
    background-color: inherit;
}

table:not([class^='MuiTable']) td {
    color: var(--text-primary);
}

.app-content {
    margin-bottom: 20px;
}

.app-content tr:hover a:hover {
    text-decoration: unset;
}

.app-content .details-value-col {
    position: relative;
}

.app-content input:not([type='checkbox']):not([class^='MuiSelect-nativeInput']):disabled {
    opacity: 0.65;
    border: 0;
}

.app-content input::placeholder {
    color: #46586e;
}

.app-content input[type='checkbox'] {
    width: 30px;
}

tbody th {
    vertical-align: middle !important;
}

.small-icon {
    position: relative;
    width: 30px;
}

.app-content .dropdown .dropdown-loading {
    margin: 9px;
}

/* Fix for material buttons */

.kyc-upload-head tr th {
    min-width: 150px;
    width: unset;
}

.fund-kyc-list th {
    width: 33%;
}

.fund-kyc-list .kyc-item-badge-clickable span {
    margin-left: 4px;
}

.trade-row {
    background-color: #29354c;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.trade-main-column tbody {
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: unset !important;
}

.trade-table-cell div p {
    color: white;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.trade-table-cell div {
    display: inline-grid;
}

.page-title {
    margin: 3rem;
}

.fund-kyc-list .kyc-dropzone-new-file .delete {
    color: red;
    cursor: pointer;
}

.notification-text {
    white-space: pre-line !important;
}

.sig-canvas {
    background-color: white;
    position: relative;
}

.font-preview div {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    color: black;
}

.vertical-signature-menu div:hover {
    background-color: #ccc;
}

.vertical-signature-menu div.active {
    background-color: #4caf50;
    color: white;
}

.kyc-file-name {
    margin-left: 5px;
    display: inline-table;
}

.kyc-delete-file {
    max-width: 60px;
    width: 10%;
    white-space: nowrap;
    text-align: right;
}

.kyc-review-td {
    width: 80%;
}

.app-content .kyc-delete-file {
    vertical-align: middle;
}

.kyc-file-icon-div svg {
    vertical-align: middle;
}

.legacy-table-wrap,
.div-table-color {
    background-color: var(--bg-paper);
    padding: 1rem;
    border: none !important;
    border-radius: var(--border-radius-xs);
}

.coupon-text {
    font-size: 30px;
    color: lightgreen;
}

.table-color {
    background-color: var(--control-bg);
}

.ledger-table tbody {
    display: block;
    max-height: 300px;
    overflow: auto;
}

.ledger-table thead,
.ledger-table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.ledger-important-row td {
    color: rgb(190, 148, 10);
}

.facility-list-row td {
    vertical-align: middle;
}

.facility-list-row label {
    padding-left: 5px;
    vertical-align: sub;
    margin-bottom: 0;
}

.facility-list-row th span {
    vertical-align: sub;
}

.facility-edit-access-container {
    display: inline-block;
    vertical-align: inherit;
    padding: 5px;
}

.facility-edit-access-container:hover svg,
.facility-edit-access-container:hover label {
    cursor: pointer;
    opacity: 0.5;
}
