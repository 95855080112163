.wrap {
    display: flex;
}

.step {
    --step-height: 48px;
    --arrow-width: 20px;
    --step-icon-size: 24px;
    --gradient-active-end-color: var(--gradient-success-end-color);
    --gradient-active: var(--gradient-success);

    --gradient-incomplete-end-color: #ff5c5c;
    --gradient-incomplete: linear-gradient(
        to right,
        #203347,
        #464471,
        #884a87,
        #cd4a7f,
        var(--gradient-incomplete-end-color)
    );

    --gradient-inactive-end-color: #1f233b;
    --gradient-inactive: linear-gradient(
        to right,
        #262c46,
        #242a43,
        #222740,
        #21253e,
        var(--gradient-inactive-end-color)
    );

    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    position: relative;
    height: 48px;
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    background: var(--gradient-inactive);
    color: var(--secondary);
    cursor: pointer;
    transition: 0.2s ease color;
}

.step:not(:last-child):after {
    content: '';
    display: flex;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: calc(var(--step-height) / 2) 0 calc(var(--step-height) / 2) var(--arrow-width);
    border-color: transparent transparent transparent var(--gradient-inactive-end-color);

    right: calc(var(--arrow-width) * -1);
    position: absolute;
    z-index: 1;
}

.active,
.completeStep {
    background: var(--gradient-active);
}

.active:not(:last-child):after,
.completeStep:not(:last-child):after {
    border-color: transparent transparent transparent var(--gradient-active-end-color);
}

.active,
.step:hover {
    color: #fff;
}

.completeStep,
.completeStepIcon {
    color: var(--success);
}

.incompleteStep,
.incompleteStepIcon {
    color: var(--error);
}

.completeStepIcon,
.incompleteStepIcon {
    font-size: var(--step-icon-size);
}

.stepIcon,
.completeStepIcon,
.incompleteStepIcon {
    margin-right: 16px;
}

.stepIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--step-icon-size);
    height: var(--step-icon-size);
    border-radius: 50%;
    border: 1px solid currentColor;
}
