.input input {
    text-align: center;
    color: var(--success);
    padding-left: 0;
    padding-right: 0;
}

.inputMedium {
    border-radius: var(--border-radius-sm);
}

.inputMedium input {
    max-width: 40px;
    font-size: 30px;
    height: 30px;
}

.inputLarge {
    border-radius: var(--border-radius-sm);
}

.inputLarge input {
    max-width: 48px;
    font-size: 48px;
    height: 50px;
}
