.menuGroupTrigger {
    cursor: pointer;
}

div.menuGroupContainer {
    pointer-events: all;
    background-color: var(--bg-paper);
}

div.menuGroupContainer a {
    text-decoration: none;
}

div.menuGroupContainer li {
    display: flex;
    justify-content: center;
    align-items: center;
}

div.menuGroupContainer li span {
    max-width: 200px;
    white-space: break-spaces;
    text-align: center;
}

div.menuGroupContainer a:global(.inactive) {
    color: var(--text-secondary);
}

div.menuGroupContainer a:global(.inactive) {
    color: var(--text-secondary);
}

div.menuGroupContainer a:global(.active) {
    color: var(--primary);
}

div.menuGroupContainer a:global(.active) li {
    background-color: var(--control-bg);
    border-bottom: 2px solid var(--primary);
}

div.menuGroupContainer a:global(.active) li:hover {
    color: var(--primary);
}

div.menuGroupContainer a:global(.inactive) li:hover {
    color: var(--primary);
}

div.menuGroup {
    pointer-events: none;
}
