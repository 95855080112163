.container,
.menuItemsContainer {
    display: flex;
    overflow: hidden;
}

.menuItemsContainer {
    margin-right: 64px;
    min-width: 120px;
}

.container :global(.content) {
    overflow: auto;
    white-space: nowrap;
}

.container :global(.item) {
    color: var(--text-secondary);
    white-space: nowrap;
    margin-right: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
    display: inline-block;
    text-decoration: none;
}

.container :global(.item:hover) {
    color: var(--primary);
}

.container :global(.item.active) {
    color: var(--primary);
    border-bottom: var(--primary) solid 2px;
}

.hidden {
    visibility: hidden;
}
