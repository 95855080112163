.collapsibleRow {
    background: var(--bg-paper-dark);
}

td.checkboxCell:global(.MuiTableCell-root) {
    padding-left: 12px;
}

td.actionsCell:global(.MuiTableCell-root) {
    padding-right: 0;
}
