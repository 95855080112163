.fixWebkitAutocompleteEndAdornment {
    padding: 0;
}

.fixWebkitAutocompleteEndAdornment input {
    padding-right: 40px;
}

.fixWebkitAutocompleteEndAdornment .endAdornment {
    position: absolute;
    right: 10px;
}
