.backButtonContainer,
.backButtonContainer a {
    color: var(--text-secondary);
    font-weight: 600;
    font-size: 16px;
}

.backButtonContainer:hover a {
    color: var(--text-secondary-dark);
}
