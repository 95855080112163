.container {
    margin-bottom: 0;
}

.title {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.23;
    letter-spacing: 0.2px;
    margin-bottom: 4px;
}

.description {
    font-size: 14px;
}

.content {
    margin-top: 16px;
}

.requiredAsterisk:after {
    content: '*';
    margin-left: 2px;
    color: var(--error);
    line-height: 0;
    user-select: none;
}
