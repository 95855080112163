.wrap[class^='MuiTableRow'],
.wrap {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    opacity: 0.8;
    cursor: progress;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.wrap .cell[class^='MuiTableCell'] {
    padding: 0;
    background: transparent;
}
