.spinner {
    --size: 24px;

    width: var(--size);
    height: var(--size);
    position: absolute;
    left: calc(50% - calc(var(--size) / 2));
    top: calc(50% - calc(var(--size) / 2));
}

.fullWidth {
    width: 100%;
}
