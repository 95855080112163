.link {
    transition: 0.2s ease color;
}

.link:hover,
.link:hover [class^='MuiSvgIcon'] {
    color: var(--primary);
}

.inheritColor {
    color: currentColor;
}

.lastWordWrap {
    height: 14px;
    display: inline-flex;
    align-items: center;
}
