.focusedState td {
    background: var(--bg-paper-light);
    transition: 0.2s ease background;
}

.focusedState td:first-child {
    border-bottom-left-radius: 0;
}

.focusedState td:last-child {
    border-bottom-right-radius: 0;
}
