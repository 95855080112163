.wrap {
    --header-bg: #1b2032;
    --border-dark: #272e48;
    --header-height: 64px;
    --logo-offset: 5%;

    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    min-width: 1024px;
}

.header.header {
    padding: 0 var(--logo-offset);
}

.logo {
    height: 24px;
}

.content {
    display: flex;
    justify-content: center;
    flex: 1;
}

.contentLeft {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 30%;
    max-width: 500px;
    min-width: 340px;
    background: var(--bg-default);
    padding: 5vh var(--logo-offset);
}

.contentRight {
    display: flex;
    flex: 1;
    padding: 100px;
    /* TODO make this loaded as resource to remove from bundle */
    background: url('../../../../../public/img/login-bg.jpg') no-repeat right 0;
    background-size: cover;
}

.promo {
    --text-offset: 5%;

    display: flex;
    flex-direction: column;
    line-height: 1;
}

.promoImg {
    max-width: 100px;
    max-height: 100px;
    margin-bottom: 40px;
    user-select: none;
}

.promoText {
    display: flex;
    font-size: 50px;
    font-weight: 500;
    margin: 0 0 12px 0;
}

.disclaimerText {
    text-align: center;
    font-weight: 500;
    width: 100%;
}
