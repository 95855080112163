.area {
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.area:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: currentColor;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.2;
}

.square:before {
    border-radius: 0;
}

.round:before {
    border-radius: 50%;
}

.squareRounded:before {
    border-radius: var(--border-radius-md);
}

.cornerRadiusXs:before {
    border-radius: var(--border-radius-xs);
}

.cornerRadiusSm:before {
    border-radius: var(--border-radius-sm);
}

.cornerRadiusMd:before {
    border-radius: var(--border-radius-md);
}

.cornerRadiusLg:before {
    border-radius: var(--border-radius-lg);
}

.cornerRadiusXl:before {
    border-radius: var(--border-radius-xl);
}
