.wrap {
    background: var(--bg-paper-dark);
    padding: 32px;
    display: flex;
    justify-content: center;
}

.qrCodeWRap {
    --imageSize: 180px;

    float: right;
    margin-left: 32px;
}

.qrCodeImg {
    width: var(--imageSize);
    height: var(--imageSize);
    border-radius: var(--border-radius-lg);
    overflow: hidden;
}

.list {
    padding-left: 16px;
}

.list li {
    margin-bottom: 8px;
}

.note {
    margin-top: 16px;
    width: var(--imageSize);
    font-size: 12px;
    text-indent: -8px;
    padding-left: 8px;
}
