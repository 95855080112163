.area {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius-md);
    background: var(--bg-black-a80);
    cursor: pointer;
    position: relative;
}

.spinner {
    position: absolute;
}

.areaSmall {
    padding: 20px;
}

.icon {
    font-size: 40px;
}

.area p {
    font-weight: 600;
    color: var(--secondary);
    margin: 0;
}

.areaTextWrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.button {
    min-width: 160px;
    pointer-events: none;
}

.errorMessageTitle {
    font-weight: 600;
    margin-right: 4px;
}

.errorMessageList {
    padding-left: 16px;
}
