.wrap {
    display: flex;
}

.avatar {
    font-weight: 500;
    font-size: 14px;
    height: 30px;
    width: 30px;
    cursor: pointer;
}

.avatar.sizeSmall {
    width: 24px;
    height: 24px;
    font-size: 12px;
}

.nameText {
    color: var(--text-primary);
    text-overflow: ellipsis;
    overflow: hidden;
}

.companyText {
    color: var(--text-secondary);
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 10px;
}

.profileMenuButton {
    padding: 0;
    min-width: 24px;
    margin-left: 4px;
    height: 32px;
}

.textWrap {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.iconWrap {
    margin: 0 12px 0 -5px;
}
