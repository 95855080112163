.container {
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.background,
.backgroundDark {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.background {
    background: var(--bg-default);
}

.backgroundDark {
    background: var(--bg-paper);
}

.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.description {
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    width: 420px;
    margin-bottom: 0;
    margin-top: 8px;
}

.title {
    margin-top: 12px;
    text-align: center;
    color: var(--text-primary);
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    width: 400px;
    margin-bottom: 0;
}

.actionContainer {
    margin-top: 24px;
}

.image {
    width: 120px;
}
