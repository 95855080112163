.fileItem {
    display: flex;
    align-items: center;
    padding: 20px 16px 20px 20px;
    border-radius: var(--border-radius-md);
    background: var(--bg-black-a80);
}

.fileItem:not(:last-child) {
    margin-bottom: 8px;
}

.iconBox {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    color: var(--yellow);
    background: var(--yellow-a20);
    border-radius: var(--border-radius-sm);
}

.fileNameContainer {
    display: flex;
    flex-direction: column;
    max-width: 75%;
    margin-right: auto;
}

.fileNameWrap {
    line-height: 1;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.fileExtension {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1;
    color: var(--secondary);
}
