.container {
    display: flex;
    flex-direction: column;
}

.controls {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 8px;
    border-bottom: 1px solid var(--bg-paper-dark);
    border-top-left-radius: var(--border-radius-md);
    border-top-right-radius: var(--border-radius-md);
    background: var(--bg-default);
}

.scratchPadWrap {
    display: flex;
    background: var(--bg-default);
    border-bottom-left-radius: var(--border-radius-md);
    border-bottom-right-radius: var(--border-radius-md);
    flex-direction: column;
}

.scratchPad {
    margin: 12px;
    display: flex;
    flex: 1;
    background: #fff;
    border-radius: 6px;
    height: 100%;
    min-height: 200px;
    position: relative;
}

.scratchPad:after {
    content: '';
    display: flex;
    position: absolute;
    width: 90%;
    border-top: 1px dotted var(--bg-default);
    opacity: 0.5;
    bottom: 50px;
    left: 5%;
    pointer-events: none;
}

.preview {
    display: flex;
    justify-content: center;
    height: 100%;
    border-radius: var(--border-radius-md);
    overflow: hidden;
}

.preview img {
    max-width: 100%;
}

.signatureCanvas {
    width: 100%;
}

.droparea {
    padding: 12px;
}
