.container {
    display: flex;
    height: 70vh;
    margin: -24px -24px -24px -24px;
}

.leftSectionWrap {
    display: flex;
    min-width: 22%;
    max-width: 30%;
    flex-direction: column;
}

.leftSection {
    padding: 8px 0;
    overflow-y: auto;
    flex: 1;
}

.leftSection :global(.MuiTreeItem-label) {
    padding-right: 8px;
    font-weight: 500;
}

.deletedItemsText {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--text-primary);
}

.rightSection {
    flex: 1;
    padding: 16px 24px 24px 24px;
    overflow-y: auto;
}

.rightSection > h5 {
    position: sticky;
    top: 0;
    z-index: 4;
    padding: 16px 0 16px;
    margin: 0;
}

.rightSection,
.rightSection > h5 {
    background-color: var(--bg-default);
}

.deletedDocumentsMenuItem:hover,
.deletedDocumentsMenuItem:global(.Mui-Selected) {
    background-color: var(--primary-a10);
}
.deletedDocumentsMenuItem {
    border-top: 1px solid var(--bg-default);
    transition: 0.2s ease background-color;
}

.deletedDocumentsMenuItemIconWrap {
    margin-right: 8px;
}
