.publicAccess {
    color: var(--yellow);
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
}

.privateAccess {
    color: var(--success);
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
}

.accessLabel {
    margin-bottom: 0;
    vertical-align: bottom;
}
